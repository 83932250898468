export let i18n = [
    {
        name: 'en-US',
        firstDayOfWeek: 0,
        data: {
            night: 'Night',
            nights: 'Nights',
            week: 'Week',
            weeks: 'Weeks',
            'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
            'check-in': 'Check-in',
            'check-out': 'Check-out',
            'month-names': [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            tooltip: {
              halfDayCheckIn: 'Available CheckIn',
              halfDayCheckOut: 'Available CheckOut',
              saturdayToSaturday: 'Only Saturday to Saturday',
              sundayToSunday: 'Only Sunday to Sunday',
              minimumRequiredPeriod: '%{minNightInPeriod} %{night} minimum.',
            },
        },
        format: 'MM-DD-YYYY'
    },
    {
        name: 'en-GB',
        firstDayOfWeek: 1,
        data: {
            night: 'Night',
            nights: 'Nights',
            week: 'Week',
            weeks: 'Weeks',
            'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
            'check-in': 'Check-in',
            'check-out': 'Check-out',
            'month-names': [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            tooltip: {
              halfDayCheckIn: 'Available CheckIn',
              halfDayCheckOut: 'Available CheckOut',
              saturdayToSaturday: 'Only Saturday to Saturday',
              sundayToSunday: 'Only Sunday to Sunday',
              minimumRequiredPeriod: '%{minNightInPeriod} %{night} minimum.',
            },
        },
        format: 'DD/MM/YYYY'
    },
    {
        name: 'pt-BR',
        firstDayOfWeek: 1,
        data: {
            night: 'Noite',
            nights: 'Noites',
            week: 'Semana',
            weeks: 'Semanas',
            'day-names': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            'check-in': 'Chegada',
            'check-out': 'Partida',
            'month-names': [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            tooltip: {
                halfDayCheckIn: 'Chegada possíveis',
                halfDayCheckOut: 'Partida possíveis',
                saturdayToSaturday: 'Sábado a Sábado apenas',
                sundayToSunday: 'Domingo a domingo apenas',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} mínimo.'
            }
        },
        format: 'DD/MM/YYYY'
    },
    {
        name: 'da-DK',
        firstDayOfWeek: 1,
        data: {
            night: 'Nat',
            nights: 'Nætter',
            week: 'Uge',
            weeks: 'Uger',
            'day-names': ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
            'check-in': 'Check-in',
            'check-out': 'Check-ud',
            'month-names': [
                'Januar',
                'Februar',
                'Marts',
                'April',
                'Maj',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'December'
            ],
            tooltip: {
                halfDayCheckIn: 'Tilgængelig Check-in',
                halfDayCheckOut: 'Tilgængelig Check-ud',
                saturdayToSaturday: 'Kun Lørdag til Lørdag',
                sundayToSunday: 'Kun Søndag til Søndag',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} minimum.'
            }
        },
        format: 'DD.MM.YYYY'
    },
    {
        name: 'de-DE',
        firstDayOfWeek: 1,
        data: {
            night: 'Nacht',
            nights: 'Nächte',
            week: 'Woche',
            weeks: 'Wochen',
            'day-names': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            'check-in': 'Anreise',
            'check-out': 'Abreise',
            'month-names': [
              'Januar',
              'Februar',
              'März',
              'April',
              'Mai',
              'Juni',
              'Juli',
              'August',
              'September',
              'Oktober',
              'November',
              'Dezember'
            ],
            tooltip: {
                halfDayCheckIn: 'Halbtags-Anreise verfügbar',
                halfDayCheckOut: 'Halbtags-Abreise verfügbar',
                saturdayToSaturday: 'Nur von Samstag bis Samstag',
                sundayToSunday: 'Nur von Sonntag bis Sonntag',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} Mindestaufenthalt.'
            }
        },
        format: 'DD.MM.YYYY'
    },
    {
        name: 'it-IT',
        firstDayOfWeek: 1,
        data: {
            night: 'Notte',
            nights: 'Notti',
            week: 'Settimana',
            weeks: 'Settimane',
            'day-names': ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
            'check-in': 'Check-in',
            'check-out': 'Check-out',
            'month-names': [
                'Gennaio',
                'Febbraio',
                'Marzo',
                'Aprile',
                'Maggio',
                'Giugno',
                'Luglio',
                'Agosto',
                'Settembre',
                'Ottobre',
                'Novembre',
                'Dicembre'
            ],
            tooltip: {
                halfDayCheckIn: 'Check-in Disponibile',
                halfDayCheckOut: 'Check-out Disponibile',
                saturdayToSaturday: 'Solo da Sabato a Sabato',
                sundayToSunday: 'Solo da Domenica a Domenica',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} minimo.'
            }
        },
        format: 'DD/MM/YYYY'
    },
    {
        name: 'es-ES',
        firstDayOfWeek: 1,
        data: {
            night: 'Noche',
            nights: 'Noches',
            week: 'Semana',
            weeks: 'Semanas',
            'day-names': ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
            'check-in': 'Arribo',
            'check-out': 'Partida',
            'month-names': [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],
            tooltip: {
                halfDayCheckIn: 'Arribo Disponible',
                halfDayCheckOut: 'Partida Disponible',
                saturdayToSaturday: 'Sólo Sábados a Sábados',
                sundayToSunday: 'Sólo Domingo a Domingo',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} mínimo.',
            }
        },
        format: 'DD/MM/YYYY'
    },
    {
        name: 'fr-FR',
        firstDayOfWeek: 1,
        data: {
            night: 'Nuit',
            nights: 'Nuits',
            week: 'Semaine',
            weeks: 'Semaines',
            'check-in': 'Départ',
            'check-out': 'Arrivée',
            'day-names': ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
            'month-names': [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre'
            ],
            tooltip: {
                halfDayCheckIn: 'Réservation possible',
                halfDayCheckOut: 'Réservation possible',
                saturdayToSaturday: 'Du samedi au samedi uniquement',
                sundayToSunday: 'Du dimanche au dimanche uniquement',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} minimum'
            }
        },
        format: 'DD/MM/YYYY'
    },
    {
        name: 'zh-Hans',
        firstDayOfWeek: 1,
        data: {
            night: '晚上',
            nights: '晚',
            week: '星期',
            weeks: '周',
            'day-names': ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            'check-in': '入住',
            'check-out': '退房',
            'month-names': [
                '一月',
                '二月',
                '三月',
                '四月',
                '五月',
                '六月',
                '七月',
                '八月',
                '九月',
                '十月',
                '十一月',
                '十二月'
            ],
            tooltip: {
                halfDayCheckIn: '可用入住',
                halfDayCheckOut: '可用退房',
                saturdayToSaturday: '只能周六到周六',
                sundayToSunday: '只能周日到周日',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} 最低。'
            }
        },
        format: 'DD/MM/YYYY'
    },
    {
        name: 'kr-KR',
        firstDayOfWeek: 1,
        data: {
            night: '밤',
            nights: '밤',
            week: '주',
            weeks: '주',
            'day-names': ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
            'check-in': '체크인',
            'check-out': '체크아웃',
            'month-names': [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월'
            ],
            tooltip: {
                halfDayCheckIn: '사용 가능한 체크인',
                halfDayCheckOut: '사용 가능한 체크아웃',
                saturdayToSaturday: '토요일부터 토요일까지만',
                sundayToSunday: '일요일부터 일요일까지만',
                minimumRequiredPeriod: '%{minNightInPeriod} %{night} 최소.'
            }
        },
        format: 'DD/MM/YYYY'
    }
]

