import Vue from 'vue';
import IsoBookingButtonForm from './form/IsoBookingButtonForm.vue';

const isoBookingButtonModules = document.querySelectorAll('[data-iso-booking-button-module]');
//console.log(isoBookingButtonModules);
Vue.component('iso-booking-button-form', IsoBookingButtonForm);

if (isoBookingButtonModules.length > 0) {

    isoBookingButtonModules.forEach((element) => {
		new Vue({
            el: element,
            components: {
                IsoBookingButtonForm
            }
		});
	});
}